<template>
  <Workspace>
    <Viewer />
  </Workspace>
</template>

<script>
import Viewer from "@/views/App/CoffreFort/ObjectStorage/Viewer";
import Workspace from "@/components/Workspaces/Workspace";
export default {
  name: "Dashboard",
  components: { Workspace, Viewer },
  mounted() {
    this.$store.commit("updateHeader", {
      title: "Mes documents",
      subtitle: "Vos documents Kermony en un seul endroit !",
    });
  },
};
</script>
