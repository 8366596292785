<template>
  <div class="kermony-breadcrumb">
    <a-breadcrumb>
      <template #separator>
        <svg
          style="transform: translateY(3px)"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 8L14 12L10 16"
            stroke="#21244E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>
      <a-breadcrumb-item
        ><a href="javascript:void(0);" @click="clickOnRoot()"
          >Accueil</a
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item v-for="(folder, index) in path" :key="folder"
        ><a href="javascript:void(0);" @click="handleClick(folder, index)">{{
          folder
        }}</a></a-breadcrumb-item
      >
    </a-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "FolderPath",
  props: ["path"],
  methods: {
    handleClick(directoryName, index) {
      this.$emit("clickOnFolder", directoryName, index);
    },
    clickOnRoot() {
      this.$emit("clickOnRoot");
    },
  },
};
</script>

<style scoped>
.kermony-breadcrumb .ant-breadcrumb {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 25px;
  line-height: 32px;
}

.ant-breadcrumb a:hover {
  color: var(--kermony-office-violet) !important;
}
</style>
