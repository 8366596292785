<template>
  <div class="row folder" @click="onClick">
    <span class="icon">
      <Icon
        name="folder"
        size="thin"
        color="var(--kermony-office-bleu-2)"
      ></Icon>
    </span>
    <span class="name">{{ name }}</span>
    <span class="type hidden-mobile" v-if="showColumnType">Dossier</span>
    <span class="size hidden-mobile">--</span>
    <span class="date hidden-mobile">--</span>
    <Actions
      :on-delete="onDelete"
      :can-download="false"
      :can-delete="canDelete"
    />
  </div>
</template>

<script>
import Icon from "@/components/Icons/Icon";
import Actions from "@/components/Tables/TableFiles/Actions";
export default {
  name: "RowFolder",
  components: { Actions, Icon },
  methods: {},
  props: [
    "name",
    "size",
    "date",
    "onClick",
    "canDelete",
    "onDelete",
    "showColumnType",
  ],
};
</script>

<style scoped>
.folder {
  cursor: pointer;
}

.row.header > * {
  display: flex;
  align-items: center;
}

.row.header {
  gap: 16px;
  height: 24px;

  background: transparent;
  box-shadow: none;
  border-radius: 0px;

  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 1.5px;
  text-transform: uppercase;

  /* Kermony/Gris 2 */

  color: #777c7f;
}
.row.header .icon {
  background-color: transparent;
}
/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .row .name {
    width: initial !important;
  }
}
.row .name {
  width: 200px;
}
.row .size,
.row .type {
  width: 80px;
  color: #cfd2d4;
}
.row .date {
  width: 200px;
  color: #cfd2d4;
}
.row .download {
  cursor: pointer;
  margin-left: auto;
}

.row .icon {
  height: 49px;
  width: 49px;
  border-radius: 4px;
  background-color: var(--kermony-office-beige);
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 8px;
  gap: 16px;

  height: 65px;

  /* Kermony/Blanc */

  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}
</style>
