<template>
  <span class="actions">
    <span class="download" @click="_onDownload" v-if="canDownload">
      <Icon
        name="download"
        size="medium"
        color="var(--kermony-office-rose)"
      ></Icon>
    </span>
    <span class="trash" @click="_onDelete" v-if="canDelete">
      <Icon
        name="trash"
        size="medium"
        color="var(--kermony-office-rose)"
      ></Icon>
    </span>
  </span>
</template>

<script>
import Icon from "@/components/Icons/Icon";
export default {
  name: "Actions",
  components: { Icon },
  props: ["onDelete", "onDownload", "canDelete", "canDownload"],
  methods: {
    _onDelete(e) {
      e.stopPropagation();
      e.preventDefault();
      this.onDelete();
    },
    _onDownload(e) {
      e.stopPropagation();
      e.preventDefault();
      this.onDownload();
    },
  },
};
</script>

<style scoped>
.row .actions {
  margin-left: auto;
  display: flex;
  gap: 16px;
}

.row .actions > * {
  cursor: pointer;
}
</style>
