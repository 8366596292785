<template>
  <div class="table">
    <div class="row header hidden-mobile">
      <span class="icon"></span>
      <span class="name"
        ><span>Nom</span
        ><Icon
          name="arrow-down"
          size="medium"
          color="var(--kermony-gris-1)"
        ></Icon
      ></span>
      <span class="type" v-if="showColumnType">
        <span>Type</span>
        <Icon
          name="arrow-down"
          size="medium"
          color="var(--kermony-gris-1)"
        ></Icon>
      </span>
      <span class="size"
        ><span>Taille</span
        ><Icon
          name="arrow-down"
          size="medium"
          color="var(--kermony-gris-1)"
        ></Icon
      ></span>
      <span class="date"
        ><span>Crée le</span
        ><Icon
          name="arrow-down"
          size="medium"
          color="var(--kermony-gris-1)"
        ></Icon
      ></span>
    </div>

    <slot />
  </div>
</template>

<script>
import Icon from "@/components/Icons/Icon";
export default {
  name: "TableFiles",
  components: { Icon },
  props: ["showColumnType"],
  methods: {},
};
</script>

<style scoped>
.table {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  margin-bottom: 24px;
}

.row.header > * {
  display: flex;
  align-items: center;
}

.row.header {
  gap: 16px;
  height: 24px;

  background: transparent;
  box-shadow: none;
  border-radius: 0px;

  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 1.5px;
  text-transform: uppercase;

  /* Kermony/Gris 2 */

  color: #777c7f;
}
.row.header .icon {
  background-color: transparent;
}

.row .name {
  width: 200px;
}
.row .size,
.row .type {
  width: 80px;
  color: #cfd2d4;
}
.row .date {
  width: 200px;
  color: #cfd2d4;
}
.row .download {
  cursor: pointer;
  margin-left: auto;
}

.row .icon {
  height: 49px;
  width: 49px;
  border-radius: 4px;
  background-color: var(--kermony-office-beige);
  display: flex;
  justify-content: center;
  align-items: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 8px;
  gap: 16px;

  height: 65px;

  /* Kermony/Blanc */

  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}
</style>
