<template>
  <RowFile v-if="type === 'file'" v-bind="$props" />
  <RowFolder v-if="type === 'folder'" v-bind="$props" />
</template>

<script>
import RowFile from "@/components/Tables/TableFiles/RowFile";
import RowFolder from "@/components/Tables/TableFiles/RowFolder";
export default {
  name: "RowFileOrFolder",
  components: { RowFolder, RowFile },
  props: [
    "type",
    "name",
    "size",
    "date",
    "onDownload",
    "onClick",
    "canDelete",
    "onDelete",
    "showColumnType",
    "fileExtension",
  ],
};
</script>

<style scoped></style>
