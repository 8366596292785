<template>
  <!--  <div class="item" v-for="item in items" :key="item.name" @click="handleClick(item)">-->
  <!--    <span class="icon">-->
  <!--    <i v-if="item.type==='folder'" class="fa-solid fa-folder"></i>-->
  <!--    <i v-if="item.type==='file'" class="fa-solid fa-file"></i>-->
  <!--    </span>-->
  <!--    <span class="item-name">{{item.name}}</span>-->
  <!--    <div v-if="item.type==='file'" class="action">-->
  <!--      <ContextualMenu :downloadFile="downloadFile" :deleteFile="deleteFile" :canDelete="canDelete" :item="item" />-->
  <!--    </div>-->
  <!--  </div>-->

  <TableFiles :showColumnType="true" style="margin-top: 24px">
    <RowFileOrFolder
      v-for="item in items"
      :type="item.type"
      :key="item.name"
      :name="item.name"
      :size="item.size"
      :date="formatDate(item.lastModified)"
      :canDelete="canDelete"
      :onClick="() => handleClick(item)"
      :onDownload="() => downloadFile(item.name)"
      :onDelete="() => deleteFile(item.name)"
      :showColumnType="true"
      :fileExtension="getFileExtension(item.name)"
    ></RowFileOrFolder>
  </TableFiles>
</template>

<script>
import HTTP from "@/axios";
import _ from "lodash";
import { API_URL } from "@/config/url";
import TableFiles from "@/components/Tables/TableFiles";
import RowFileOrFolder from "@/components/Tables/TableFiles/RowFileOrFolder";
import moment from "moment-timezone";
export default {
  name: "ListObjects",
  components: { RowFileOrFolder, TableFiles },
  props: ["path", "refresh", "canDelete"],
  data() {
    return {
      items: [],
    };
  },
  created() {
    HTTP.get("object_storage/objects/?path=").then((response) => {
      this.items = response.data;
    });
  },
  watch: {
    refresh() {
      HTTP.get(`object_storage/objects/?path=${this.path.join("/")}`).then(
        (response) => {
          this.items = response.data;
        }
      );
    },
    path(newPath) {
      HTTP.get(`object_storage/objects/?path=${newPath.join("/")}`).then(
        (response) => {
          this.items = response.data;
        }
      );
    },
  },
  methods: {
    formatDate(d) {
      if (_.isNil(d)) {
        return null;
      }

      return moment(d).format("DD/MM/YYYY à HH:mm");
    },
    getFileExtension(itemName) {
      try {
        const index = itemName.lastIndexOf(".");
        return _.toUpper(itemName.substr(index + 1));
      } catch (e) {
        return null;
      }
    },
    downloadFile(itemName) {
      HTTP.post(`object_storage/download/`, {
        path: `${[...this.path, itemName].join("/")}`,
      }).then((response) => {
        if (response.status === 201) {
          window.open(
            `${API_URL}/object_storage/download/${response.data.token}/`,
            "_self"
          );
        }
      });
    },
    deleteFile(itemName) {
      HTTP.delete(
        `object_storage/object/?path=${[...this.path, itemName].join("/")}`
      ).then((response) => {
        if (response.status === 204) {
          this.items = _.filter(this.items, (item) => item.name !== itemName);
        }
      });
    },

    handleClick(item) {
      switch (item.type) {
        case "folder":
          this.$emit("clickOnFolder", item);
      }
    },
  },
};
</script>

<style scoped>
.item {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(207, 212, 226);
  /*margin: 10px;*/
  padding: 0px;
  display: grid;
  grid-template-columns: 60px minmax(30px, auto) 40px;
  align-items: center;
  height: 44px;
}
.item:hover {
  cursor: pointer;
  background-color: rgb(239, 239, 239);
  transition: background-color 1000ms ease-in-out;
}
.icon {
  text-align: center;
}

.item:hover .action {
  display: flex;
}
.action {
  display: none;
  justify-content: center;
}

.item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
