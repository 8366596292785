<template>
  <div style="display: grid; grid-template-columns: 1fr auto">
    <FolderPath
      :path="currentPath"
      @clickOnRoot="clickOnRoot"
      @clickOnFolder="clickOnFolderPath"
    />
    <!-- DISABLED -->
    <Button
      v-if="canUpload && !isMobile && 1 == 2"
      @click="showModalUploadFile"
      icon-left="plus"
      theme="primary"
      size="medium"
      >AJOUTER UN FICHIER</Button
    >

    <Tooltip v-else-if="!isMobile && 1 == 2">
      <template #title
        >Vous ne pouvez pas ajouter un fichier dans ce dossier</template
      >
      <div>
        <Button
          @click="showModalUploadFile"
          :disabled="!canUpload"
          icon-left="plus"
          theme="primary"
          size="medium"
          >AJOUTER UN FICHIER</Button
        >
      </div>
    </Tooltip>
  </div>

  <div id="viewer-container">
    <ListObjects
      :canDelete="canDelete && !isMobile"
      :refresh="refresh"
      :path="currentPath"
      @clickOnFolder="clickOnFolder"
    />
    <Button
      v-if="canUpload && isMobile && 1 == 2"
      @click="showModalUploadFile"
      style="width: 100%"
      icon-left="plus"
      theme="primary"
      size="medium"
      >AJOUTER UN FICHIER</Button
    >
  </div>

  <a-modal
    v-model:visible="modalUploadFileVisible"
    title="Envoyer des fichers"
    @ok="confirmUpload"
    @cancel="closeModalUpload"
    okText="Confirmer"
    cancelText="Annuler"
  >
    <template
      #footer
      v-if="
        uploadResult &&
        (uploadResult.status === 'done' || uploadResult.status === 'fail')
      "
    >
      <a-button
        key="submit"
        type="primary"
        :loading="uploadInProgress"
        @click="closeModalUpload"
        >Fermer</a-button
      >
    </template>
    <template #footer v-else>
      <a-button
        key="back"
        :disabled="uploadInProgress"
        @click="closeModalUpload"
        >{{
          uploadInProgress === true || uploadResult !== null
            ? "Fermer"
            : "Annuler"
        }}</a-button
      >
      <a-button
        key="submit"
        type="primary"
        :loading="uploadInProgress"
        @click="confirmUpload"
        >Confimer</a-button
      >
    </template>
    <div v-if="errorMsg !== null">
      <a-alert :message="errorMsg" type="error" show-icon />
    </div>
    <div v-else-if="uploadResult === null && uploadInProgress === false">
      <a-upload-dragger
        v-model:fileList="fileList"
        name="file"
        :multiple="true"
        :action="handleAction"
        @change="handleChange"
        :beforeUpload="getBeforeUpload()"
        @drop="handleDrop"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">
          Cliquez ou faites glisser les fichiers dans cette zone
        </p>
        <p class="ant-upload-hint">
          Vous pouvez envoyer un ou plusieurs fichiers
        </p>
      </a-upload-dragger>
    </div>
    <div
      v-else-if="uploadResult === null && uploadInProgress === true"
      style="text-align: center"
    >
      <a-spin :indicator="loader" />
    </div>
    <div v-else>
      <div>
        <a-alert
          style="margin-bottom: 10px"
          v-if="uploadResult.status === 'ongoing'"
          message="Mise en ligne en cours"
          type="info"
          show-icon
        />
        <a-alert
          style="margin-bottom: 10px"
          v-if="uploadResult.status === 'done'"
          message="Tous les fichiers ont été envoyés avec succès"
          type="success"
          show-icon
        />
        <a-alert
          style="margin-bottom: 10px"
          v-if="uploadResult.status === 'fail'"
          message="Une erreur est survenue"
          type="error"
          show-icon
        />

        <div v-for="file in uploadResult.files" :key="file.filename">
          <div style="display: grid; grid-template-columns: 30px 1fr">
            <a-spin :indicator="indicator" v-if="file.status === 'ongoing'" />
            <check-outlined
              v-if="file.status === 'done'"
              :style="{ color: '#52c41a', fontSize: '13px', padding: '5px' }"
            />
            <close-outlined
              v-if="file.status === 'fail'"
              :style="{
                color: 'var(--danger)',
                fontSize: '13px',
                padding: '5px',
              }"
            />

            <div
              v-if="file.status !== 'fail'"
              :style="{
                color: file.status === 'ongoing' ? 'var(--blue)' : '#52c41a',
              }"
            >
              {{ file.filename }}
            </div>
            <a-tooltip v-else color="rgb(64 64 64)" placement="bottom">
              <template #title
                >Une erreur est survenue lors de l'envoi de ce fichier</template
              >
              <div :style="{ color: 'var(--danger)' }">
                {{ file.filename }}
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import ListObjects from "./ListObjects";
import FolderPath from "@/views/App/CoffreFort/ObjectStorage/FolderPath";
import { ref, h } from "vue";
import HTTP from "@/axios";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons-vue";
import {
  CheckOutlined,
  CloseOutlined,
  InboxOutlined,
} from "@ant-design/icons-vue";
import Button from "@/components/Buttons/Button";
import Tooltip from "@/components/Tooltips/Tooltip";
import { isMobile } from "@/utils/isMobile";

export default {
  name: "Viewer",
  components: {
    Tooltip,
    Button,
    FolderPath,
    ListObjects,
    CheckOutlined,
    CloseOutlined,
    InboxOutlined,
  },
  data() {
    return {
      currentPath: [],
      refresh: 0,

      uploadResult: null,
      uploadInProgress: false,
      fileList: [],
      errorMsg: null,
      isMobile: isMobile(),
    };
  },
  computed: {
    canUpload() {
      return !_.startsWith(this.currentPath, "Mon Kermony");
    },
    canDelete() {
      return !_.startsWith(this.currentPath, "Mon Kermony");
    },
  },
  setup() {
    const modalUploadFileVisible = ref(false);

    const internalShowModalUploadFile = () => {
      modalUploadFileVisible.value = true;
    };

    const closeModalUploadFile = () => {
      modalUploadFileVisible.value = false;
    };
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: "13px",
        position: "absolute",
        padding: "5px 0px",
        marginLeft: "-5px",
        color: "var(--blue)",
      },
      spin: true,
    });
    const loader = h(LoadingOutlined, {
      style: {
        fontSize: "32px",
      },
      spin: true,
    });
    return {
      indicator,
      loader,
      internalShowModalUploadFile,
      modalUploadFileVisible,
      closeModalUploadFile,
    };
  },

  methods: {
    handleChange() {},
    handleDrop() {},
    getBeforeUpload() {
      return _.debounce(this.beforeUpload, 10);
    },
    beforeUpload(file, fileList) {
      this.fileList = fileList;
    },
    handleAction() {
      return new Promise(() => {});
    },
    showModalUploadFile() {
      this.errorMsg = null;
      this.uploadResult = null;
      this.internalShowModalUploadFile();
    },
    closeModalUpload() {
      this.closeModalUploadFile();
      this.fileList = [];
    },
    confirmUpload() {
      this.uploadInProgress = true;
      const formData = new FormData();

      this.fileList.forEach((file) => {
        formData.append("files", file);
      });

      formData.append("path", this.currentPath.join("/"));

      this.fileList = [];

      const polling = (id) => {
        HTTP.post(`object_storage/upload/${id}/status/`).then((response) => {
          this.uploadResult = response.data;

          if (response.data.status === "ongoing") {
            setTimeout(() => {
              polling(id);
            }, 500);
            return;
          }
          this.uploadInProgress = false;
          this.refresh = Math.random();
        });
      };

      HTTP.post("object_storage/objects/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.uploadResult = response.data;
          polling(response.data.id);
        })
        .catch((reason) => {
          let errorMsg = "Une erreur est survenue";
          if (_.get(reason, "response.data.error") === "file_already_exists") {
            errorMsg = `Le fichier ${_.get(
              reason,
              "response.data.data.filename",
              ""
            )} existe déjà.`;
          }

          this.errorMsg = errorMsg;
          this.uploadInProgress = false;
        });
    },
    clickOnRoot() {
      this.setCurrentPath([]);
    },
    clickOnFolderPath(directory, index) {
      this.setCurrentPath(this.currentPath.slice(0, index + 1));
    },
    clickOnFolder(directory) {
      this.setCurrentPath([...this.currentPath, directory.name]);
    },
    setCurrentPath(path) {
      this.currentPath = path;
    },
  },
};
</script>

<style scoped>
/*
#viewer-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  margin: 10px 0px;
}
 */
</style>
